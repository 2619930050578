<template>
  <div class="w-screen h-screen flex">
    <div class="w-full md:w-1/2 bg-gray-100">
      <div class="container flex flex-col w-2/3 mt-20">
        <router-link to="/">
          <img
            src="@/_assets/images/hive_logo.svg"
            alt=""
            class="w-48 h-16 object-contain mb-12"
          />
        </router-link>
        <h1 class="font-bold text-2xl text-darkblue-800 mt-12">
          Entra nel tuo negozio
        </h1>
        <h3 class="py-2 font-medium">
          Inserisci email e password inseriti in fase di registrazione
        </h3>
        <form @submit.prevent.once="login" class="flex flex-col">
          <input
            type="text"
            class="col-span-2 my-2"
            placeholder="Email"
            v-model="email"
          />
          <input
            type="password"
            class="col-span-2 my-2"
            placeholder="Password"
            v-model="password"
          />
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-1/2">
              <div class="mr-2">
                Hai dimenticato la password?
              </div>
              <router-link
                :to="`/${$route.params.lang}/forgot`"
                class="text-secondary-500 font-bold"
              >
                Recuperala</router-link
              >
            </div>
            <div class="w-1/2">
              <div class="mr-2">
                Non sei registrato?
              </div>
              <router-link
                :to="`/${$route.params.lang}/register`"
                class="text-secondary-500 font-bold"
              >
                Registrati!</router-link
              >
            </div>
          </div>
          <div class="flex items-baseline mt-12">
            <router-link to="/" class="text-secondary-500 w-full text-center">
              <i class="mdi mdi-arrow-left"></i>
              Torna indietro
            </router-link>
            <q-button
              class="w-full text-center"
              :cta="true"
              :loading="isLoading"
            >
              Continua
            </q-button>
          </div>
        </form>
        <div class="mt-4">
          <div class="text-red-500">{{ error }}</div>
        </div>
      </div>
    </div>
    <div
      class="hidden md:w-1/2 bg-darkblue-500 text-white md:flex justify-center items-center"
    >
      <img src="@/_assets/images/pay_img.png" class="" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
//import auth from "../_utils/auth";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false
    };
  },
  computed: {
    ...mapState("auth", ["error"]),
    error: {
      get() {
        return this.$store.getters["auth/error"];
      }
    },
    errorCode: {
      get() {
        return this.$store.getters["auth/errorCode"];
      }
    }
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async login() {
      console.log("Login in action");
      this.isLoading = true;
      try {
        await this.signIn({
          email: this.email,
          password: this.password
        });
        console.log("Fine Login");
      } catch (error) {
        console.log("ERRORE LOGIN ", error);
      } finally {
        this.isLoading = false;
      }
      // this.$store.dispatch("auth/signIn", {
      //   email: this.email,
      //   password: this.password
      // });
    },
    resendConfirmation() {
      this.$store.dispatch("auth/resendSignUp", { email: this.email });
    }
  }
};
</script>
<style lang="postcss" scoped></style>
